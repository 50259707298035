// Stimulus
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { DateTime } from '@easepick/datetime';

// JS libraries
import "../scripts/frontend/setup_jquery";
import "../scripts/frontend/setup_translations";

import Rails from 'rails-ujs';
Rails.start();

import 'bootstrap';

// modules
import { Fancybox } from "@fancyapps/ui";

window.DateTime = DateTime;

const application = Application.start();
import AudioClipsPlayerController from '../scripts/controllers/audio_clips_player_controller';
import BookingController from '../scripts/controllers/booking_controller';
import DatepickerController from '../scripts/controllers/datepicker_controller';
import FacebookShareController from '../scripts/controllers/facebook_share_controller';
import GalleryController from '../scripts/controllers/gallery_controller';
import PhoneNumberInputController from '../scripts/controllers/phone_number_input_controller';
import SingularPhoneNumberInputController from '../scripts/controllers/singular_phone_number_input_controller';
import ToggleContentController from '../scripts/controllers/toggle_content_controller';

application.register("audio-clips-player", AudioClipsPlayerController);
application.register("booking", BookingController);
application.register("datepicker", DatepickerController);
application.register("facebook-share", FacebookShareController);
application.register("gallery", GalleryController);
application.register("phone-number-input", PhoneNumberInputController);
application.register("singular-phone-number-input", SingularPhoneNumberInputController);
application.register("toggle-content", ToggleContentController);
